import type { Category, Shop, User } from "@/types";
import { Mail, Phone } from "lucide-react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

interface Props {
  shop: Shop;
  user?: User | null;
  shopCategories: Category[];
}

const Footer = ({ shop, user, shopCategories }: Props) => {
  return (
    <footer className="bg-kp-secondary text-kp-white py-16">
      <div className="container mx-auto px-4 grid md:grid-cols-4 gap-8">
        <div>
          <h2 className="text-2xl font-bold mb-4 text-kp-primary">
            {shop.name}
          </h2>
          <p className="text-sm text-kp-white mb-4">{shop.slogan}</p>
          <div className="flex gap-4">
            <a
              href={shop.facebook ?? "#"}
              target="_blank"
              rel="noopener noreferrer"
              className="text-kp-white hover:text-kp-accent transition-colors"
              aria-label="Facebook">
              <FaFacebook size={20} />
            </a>
            <a
              href={shop.instagram ?? "#"}
              target="_blank"
              rel="noopener noreferrer"
              className="text-kp-white hover:text-kp-accent transition-colors"
              aria-label="Instagram">
              <FaInstagram size={20} />
            </a>
            <a
              href={shop.twitter ?? "#"}
              target="_blank"
              rel="noopener noreferrer"
              className="text-kp-white hover:text-kp-accent transition-colors"
              aria-label="X/Twitter">
              <FaTwitter size={20} />
            </a>
            <a
              href={shop.tiktok ?? "#"}
              target="_blank"
              rel="noopener noreferrer"
              className="text-kp-white hover:text-kp-accent transition-colors"
              aria-label="TikTok">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                viewBox="0 0 16 16">
                <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" />
              </svg>
            </a>
          </div>
        </div>

        <div>
          <h3 className="font-semibold mb-4 text-kp-primary">
            Popular Categories
          </h3>
          <ul className="space-y-2">
            {shopCategories.map((category) => (
              <li key={category.id}>
                <a
                  href={`/shops/${shop.slug}?category=${category.slug}`}
                  className="text-kp-white hover:text-kp-accent hover:underline transition-colors">
                  {category.name}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <h3 className="font-semibold mb-4 text-kp-primary">Main Store</h3>
          <ul className="space-y-2">
            <li>
              <a
                href={`tel:${shop.phone}`}
                className="text-kp-white hover:text-kp-accent transition-colors flex items-center gap-2">
                <Phone size={16} /> {shop.phone}
              </a>
            </li>
            <li>
              <a
                href={`mailto:${shop.email}`}
                className="text-kp-white hover:text-kp-accent transition-colors flex items-center gap-2">
                <Mail size={16} /> {shop.email}
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h3 className="font-semibold mb-4 text-kp-primary">Quick Links</h3>
          <ul className="space-y-2">
            <li>
              <a
                href="/privacy-policy"
                className="text-kp-white hover:text-kp-accent hover:underline transition-colors">
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="/terms-of-service"
                className="text-kp-white hover:text-kp-accent hover:underline transition-colors">
                Terms & Conditions
              </a>
            </li>
            <li>
              <a
                href="/returns-policy"
                className="text-kp-white hover:text-kp-accent hover:underline transition-colors">
                Return, Shipping, & Refund Policy
              </a>
            </li>
            <li>
              <a
                href="/contact"
                className="text-kp-white hover:text-kp-accent hover:underline transition-colors">
                Get In Touch
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
